import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ContactUs from '../components/ContactUs';
import MapBanner from '../components/MapBanner';
import GMBA from '../components/gmba';
import GMBAServices from '../components/gmba-services';

const GMBAPage = ({ data }) => (
  <Layout homePage>
    <SEO title="GMBA" />
    <GMBA data={data} />
    <GMBAServices />
    <MapBanner data={data} />
    <ContactUs data={data} />
  </Layout>
);

GMBAPage.propTypes = {
  data: PropTypes.object,
};

export default GMBAPage;

export const query = graphql`
  query GMBAPageQuery {
    gmbaOne: file(relativePath: { eq: "gmba-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gmbaTwo: file(relativePath: { eq: "gmba-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gmbaThree: file(relativePath: { eq: "gmba-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MapBanner: file(relativePath: { eq: "map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 818) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mcgLogo: file(relativePath: { eq: "mcg-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 173, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        address
        facebook
        googleMaps
        phone
        phoneFormatted
        title
      }
    }
  }
`;
