import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const GMBA = ({ data }) => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <div className="flex flex-wrap w-full mx-auto max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-2/5">
        <div className="w-full px-4">
          <h2 className="text-3xl font-bold uppercase text-brand-blue">
            Gaming machine <br /> bases Australia
          </h2>

          <p className="mt-8 mb-4">
            We have teamed up with GMB Australia for the very best gaming
            machine bases. Gaming Machine Bases Australia design and manufacture
            premium electronic gaming machine bases for gaming venues around
            Australia. Select from bases, screens, accessories and design your
            own gaming room to fit your specifications.
          </p>
          <p className="mb-4">
            Get Gaming Machine Bases Australia products tailored to suit your
            venue.
          </p>
          <p className="mb-4">
            <span className="block font-semibold">
              Customised Gaming Benches
            </span>
            Order EGM bases in custom colours and finishes that suit your venue.
            Choose from:
          </p>
          <ul className="mb-4 ml-4 list-disc">
            <li>Custom base colours</li>
            <li>Custom benchtop colours</li>
            <li>Custom leading edge profiles</li>
            <li>Custom add-ons or finishes</li>
          </ul>
          <p className="mb-4">
            <span className="block font-semibold">
              Customised Gaming Screens
            </span>
            Colours, materials, and sizes made and fit to order. Create a unique
            solution that blends with your venue décor and layout.
          </p>
          <ul className="mb-4 ml-4 list-disc">
            <li>Custom base colours</li>
            <li>Custom benchtop colours</li>
            <li>Custom leading edge profiles</li>
            <li>Custom cut-outs or patterned screen designs</li>
          </ul>

          <p className="my-8 md:mb-0">
            <a
              className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white"
              href="https://www.gamingmachinebases.com.au/"
            >
              See more here
            </a>
          </p>
        </div>
      </div>
      <div className="relative grid w-full grid-cols-2 gap-3 px-4 md:w-3/5 md:pl-12">
        <div className="relative col-span-2 aspect-w-16 aspect-h-8">
          <div className="absolute inset-0 flex ">
            <Image
              className="flex-1"
              fluid={data.gmbaOne.childImageSharp.fluid}
            />
          </div>
        </div>

        <div className="relative aspect-w-4 aspect-h-4">
          <div className="absolute inset-0 flex ">
            <Image
              className="flex-1"
              fluid={data.gmbaTwo.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="relative aspect-w-4 aspect-h-4">
          <div className="absolute inset-0 flex ">
            <Image
              className="flex-1"
              fluid={data.gmbaThree.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

GMBA.propTypes = {
  data: PropTypes.object,
};

export default GMBA;
