import React from 'react';

import { CubeIcon, BoardIcon, PaperPencilIcon } from './vectors';

const GMBAServices = () => (
  <article className="mt-12 -mb-12 ">
    <div className="w-full px-4 py-12 mx-auto bg-gray-600 max-w-7xl">
      <h2 className="text-3xl font-bold text-center text-white uppercase">
        Get the Complete Gaming Base Services
      </h2>
      <div className="grid w-full max-w-5xl gap-12 mx-auto mt-12 md:grid-cols-3">
        <figure className="text-white">
          <CubeIcon className="w-20 mx-auto " />
          <figcaption className="mt-8 text-xl font-semibold text-center uppercase">
            Gaming base design
          </figcaption>
        </figure>
        <figure className="text-white">
          <BoardIcon className="w-20 mx-auto " />
          <figcaption className="mt-8 text-xl font-semibold text-center uppercase">
            Project Management
          </figcaption>
        </figure>
        <figure className="text-white">
          <PaperPencilIcon className="w-20 mx-auto " />
          <figcaption className="mt-8 text-xl font-semibold text-center uppercase">
            Gaming Room Design
          </figcaption>
        </figure>
      </div>

      <div className="flex justify-center mt-12">
        <a
          className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase bg-white border-2 border-white rounded hover:bg-brand-blue hover:text-white"
          href="https://www.gamingmachinebases.com.au/"
        >
          Find out more
        </a>
      </div>
    </div>
  </article>
);

export default GMBAServices;
