import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const MapBanner = ({ data }) => {
  return (
    <div
      id="contact"
      className="w-full pt-12 mx-auto border-b-2 border-brand-blue max-w-7xl"
    >
      <div className="relative flex justify-center w-full bg-brand-blue brand">
        <Image
          className="self-center w-full max-w-md mx-8"
          fluid={data.MapBanner.childImageSharp.fluid}
        />
      </div>
    </div>
  );
};

MapBanner.propTypes = {
  data: PropTypes.object,
};

export default MapBanner;
